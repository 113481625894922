// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-gallery-img-tsx": () => import("./../../../src/pages/gallery-img/[...].tsx" /* webpackChunkName: "component---src-pages-gallery-img-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery/[...].tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

